import { createTheme } from '@mui/material';
import { styleConfig } from '@/config/config';

const defaultTheme = createTheme();

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 300,
      sm: 480,
      md: 768,
      lg: 1200,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      main: '#B58C40',
      dark: '#2B2B2B',
    },
    secondary: {
      main: '#000000',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: 'Open Sans',
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 54,
          paddingTop: 9,
          paddingBottom: 8,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 15,
          fontSize: 13,
          lineHeight: 1.5,
          minWidth: 350,
          backgroundColor: styleConfig.color.bg.darkgrey,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          fontSize: 11,
          fontWeight: 600,
          color: styleConfig.color.text.lightgrey,
        },
        markLabelActive: {
          fontWeight: 600,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          paddingTop: 16,
        },
        root: {
          fontWeight: 600,
        },
        input: {
          paddingTop: 15,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        formControl: {
          marginTop: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          fontSize: 18,
          fontWeight: 800,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        fullWidth: {
          position: 'relative',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#2b2b2b',
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'Bai Jamjuree',
          fontSize: 65,
          fontWeight: 600,
          color: styleConfig.color.text.darkgrey,

          [defaultTheme.breakpoints.down('lg')]: {
            fontSize: 55,
          },

          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 45,
          },

          [defaultTheme.breakpoints.down('xs')]: {
            fontSize: 35,
          },
        },
        h2: {
          fontFamily: 'Bai Jamjuree',
          fontSize: 45,
          fontWeight: 600,
          color: styleConfig.color.text.darkgrey,

          [defaultTheme.breakpoints.down('md')]: {
            fontSize: 40,
          },
        },
        h3: {
          fontFamily: 'Bai Jamjuree',
          fontSize: 36,
          fontWeight: 500,
          color: styleConfig.color.text.darkgrey,

          [defaultTheme.breakpoints.down('md')]: {
            fontSize: 32,
          },
        },
        h4: {
          fontFamily: 'Bai Jamjuree',
          fontSize: 30,
          fontWeight: 500,
          color: styleConfig.color.text.darkgrey,
        },
        h5: {
          fontFamily: 'Bai Jamjuree',
          fontSize: 24,
          fontWeight: 400,
          color: styleConfig.color.text.darkgrey,
        },
        h6: {
          fontFamily: 'Bai Jamjuree',
          fontSize: 20,
          fontWeight: 400,
          color: styleConfig.color.text.darkgrey,
        },
        body1: {
          fontSize: 16,
          color: styleConfig.color.text.gray,

          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 14,
          },
        },
        body2: {
          fontSize: 18,
          color: styleConfig.color.text.darkgrey,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '10px 5px',
            margin: 0,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#E9E9E9',
          '& $text': {
            fill: '#FFF',
            fontWeight: 600,
          },
          width: 40,
          height: 40,
          '&.active': {
            '& $text': {
              fill: '#FFF',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: '#B58C40',
          color: '#FFF',
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: `${styleConfig.color.text.darkgrey}!important`,
            color: '#FFF',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        alternativeLabel: {
          fontSize: 14,
          '& $active': {
            fontWeight: 600,
          },
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 13,
          },
        },
      },
    },
  },
});

export default theme;
